
const observerOptions = {
  root: null,
  threshold: 1,
};

document.addEventListener("DOMContentLoaded", function () {
  jQuery(function ($) {
    var btn = document.getElementById("loadMoreEvents");
    if (btn) {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
        var wrapper = document.querySelector(
          ".sppoh-visual-block__content--events"
        );
        var count = parseInt(wrapper.getAttribute("data-month"));
        var year = parseInt(wrapper.getAttribute("data-year"));
        var lang = wrapper.getAttribute("data-lang");
        var $trigger = $(".sppoh-event-listing__trigger");

        $.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          dataType: "html",
          data: {
            action: "sppoh_infinite_scroll_events",
            month: count,
            year: year,
            lang: lang,
          },
          beforeSend: function () {
            wrapper.classList.add(
              "sppoh-visual-block__content--events__loading"
            );
            $(".sppoh-event-listing__trigger").addClass(
              "sppoh-event-listing__trigger--loading"
            );
            $trigger.addClass("sppoh-event-listing__trigger--loading");
          },
          success: function (res) {

            res = JSON.parse(res);
            $(".sppoh-event-listing__more").append(res.html);

            // new date is a string like yyyy-mm-dd
            var new_date = new Date(res.new_date);
            wrapper.setAttribute("data-month", new_date.getMonth());
            wrapper.setAttribute("data-year", new_date.getFullYear());


            $trigger.removeClass(
              "sppoh-event-listing__trigger--loading"
            );
            wrapper.classList.remove(
              "sppoh-visual-block__content--events__loading"
            );
          },
        });
      });
    }
  });
});