import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("block-splide__splide").length >= 1) {
    var splide = new Splide(".block-splide__splide", {
      type: "loop",
      drag: "free",
      focus: 1,
      trimSpace: false,
      pagination: false,
      arrows: false,
      perPage: 4,
      perMove: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      autoScroll: {
        speed: 0.4,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        767: {
          perPage: 3,
        },
        425: {
          perPage: 2,
        },
      },
    });
    // splide.mount();
    splide.mount({ AutoScroll });
  }
});
