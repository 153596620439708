//custom code
import "./custom/scroller";
import "./custom/heading-animation";
import "./custom/search";
import "./custom/searchFunc";
import "./custom/likeDislike";
import "./custom/eventScroll";
import "./custom/rellax";
import "./custom/form";
import "./custom/datatables";
import "./custom/map";
import "./custom/faq";
import "./custom/mobile-menu";
import "./custom/splide";

//libraries
import "./lib/fslightbox";

document.addEventListener("DOMContentLoaded", function () {
  if (
    document.body.classList.contains("post-type-archive-bijeenkomst") &&
    document.documentElement.getAttribute("lang") == "en-US"
  ) {
    document.querySelector("#breadcrumbs .breadcrumb_last")!.innerText =
      "Meetings";
  }
});
